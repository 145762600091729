import Slider from "react-slick/lib/slider"
import ContainerMain from "src/pages/common/ContainerMain"
import image1 from "images/Company/OurOffice/1.png"
import image2 from "images/Company/OurOffice/2.png"
import image3 from "images/Company/OurOffice/3.png"
import image4 from "images/Company/OurOffice/4.png"
import image5 from "images/Company/OurOffice/5.png"
import image6 from "images/Company/OurOffice/6.png"
import image7 from "images/Company/OurOffice/7.png"
import image8 from "images/Company/OurOffice/8.png"
import prevSliderIcon from "images/prev_slider_incon_gray.svg"
import nextSliderIcon from "images/next_slider_incon_gray.svg"
import "./OurOffice.scss"
import { useFormatter } from "helpers/i18n"

const news = [
  {
    id: 1,
    image: image1,
  },
  {
    id: 2,
    image: image2,
  },
  {
    id: 3,
    image: image3,
  },
  {
    id: 4,
    image: image4,
  },
  {
    id: 5,
    image: image5,
  },
  {
    id: 6,
    image: image6,
  },
  {
    id: 7,
    image: image7,
  },
  {
    id: 8,
    image: image8,
  },
]
export default function OurOffice() {
  const { __ } = useFormatter()

  const settings = {
    dots:
      typeof window !== "undefined" &&
      window.matchMedia("screen and (min-width: 1024px)").matches
        ? false
        : true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="career our-office">
      <div className="WdZHPaJUsL">
        <ContainerMain>
          <div className="section-title svoVqdrGDg">
            {__({ defaultMessage: "Our Office" })}
          </div>
          <Slider className="bYrVNJdzkW" {...settings} autoplay={true}>
            {news.map(item => {
              return (
                <div className="EePOugTXGX" key={item.id}>
                  <NewsCard data={item} />
                </div>
              )
            })}
          </Slider>
        </ContainerMain>
      </div>
    </div>
  )
}

const NewsCard = ({ data }) => {
  return (
    <div className="zGDsKXiVOp">
      <div className="QypwTPNqoS">
        <img alt="image" className="SJVEIkshKX" src={data.image} />
      </div>
    </div>
  )
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      src={nextSliderIcon}
      className={className}
      style={{
        ...style,
        display: "block",
        color: "#777777",
        fontSize: 14,
      }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      src={prevSliderIcon}
      className={className}
      style={{ ...style, display: "block", color: "#777777", fontSize: 14 }}
      onClick={onClick}
    />
  )
}
